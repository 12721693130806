import { useLocation, matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useActiveLink(path, strict) {
  const { pathname } = useLocation();

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;
  if(path == 'home'){

    return pathname === "/" || pathname === "/home"  ? true : false
  }else{

    return strict ? pathname == `/${path}` : pathname.includes(path);
  }
  
}
