import React, { useEffect, useState } from 'react';
import { getTrackingStatus } from 'src/services/delivery';
import Chip from '@mui/material/Chip';
import { Button } from '@mui/material';
import { useTheme } from '@emotion/react';
export default function GetTrackingStatus({ trackingId, ...others }) {
  const [trackingStatus, setTrackingStatus] = useState('NONE');

  const theme = useTheme();

  useEffect(() => {
    getTrackingStatus(trackingId)
      .then((res) => {
        setTrackingStatus(res?.delivery);
      })
      .catch((err) => {
        setTrackingStatus('NONE');
      });
  }, [trackingId]);

  if(trackingStatus && trackingStatus=="Delivered"){
    return     <Button variant="contained" sx={{mb:.2, color: "white !important"}} color="success" {...others}>
    { trackingStatus}
  </Button>
  }
  return <Button variant='contained' style={{color:"white"}} sx={{mb:.2,
    '&:hover': {
    backgroundColor: "#CC0100",
    color: 'white', 
  },
}} {...others} >{trackingStatus ?  trackingStatus : 'N/A'}</Button>;
}
