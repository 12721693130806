import React from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import {
  calculateFinalAmount,
  calculateDiscountAmount,
  calculateTaxAmount,
  calculateContractAmount,
} from 'src/utils/quoteHelperFunc';
import { formatCurrencyWithSymbol } from 'src/utils/currencyFormat';
export default function ContractAmountDisplay({ data }) {
  return (
    <>
      <Stack sx={{color:"white"}}>
        <Typography textTransform="uppercase" variant="subtitle1" gutterBottom>
          Total contract amount
        </Typography>
        <Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">Subtotal</Typography>
            <Typography variant="body2" textTransform="uppercase" >
              {formatCurrencyWithSymbol(calculateContractAmount(data), data?.currency?.abbreviation)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">
              Discount ({(data?.addondiscountrate || 0) + '%'})
            </Typography>
            <Typography textTransform="uppercase" variant="body2" >
              - &nbsp;
              {formatCurrencyWithSymbol(calculateDiscountAmount(data), data?.currency?.abbreviation)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">Shipping</Typography>
            <Typography variant="body2" textTransform="uppercase">
              + {formatCurrencyWithSymbol(data?.addonshippingHandling, data?.currency?.abbreviation)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">AIR FREIGHT</Typography>
            <Typography variant="body2" textTransform="uppercase">
              {formatCurrencyWithSymbol((data?.airFreight), data?.currency?.abbreviation)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">Tax</Typography>
            <Typography variant="body2" textTransform="uppercase">
              + {formatCurrencyWithSymbol(calculateTaxAmount(data), data?.currency?.abbreviation)}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1, borderColor:'red' }} />
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">Total</Typography>
            <Typography variant="subtitle1" textTransform="uppercase">
              {formatCurrencyWithSymbol(calculateFinalAmount(data), data?.currency?.abbreviation)}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1, borderColor:'red' }} />
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">CGST ({data?.cgst}%)</Typography>
            <Typography variant="body2" textTransform="uppercase">
              {formatCurrencyWithSymbol((data?.cgst)*(calculateFinalAmount(data))/100, data?.currency?.abbreviation)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">SGST ({data?.sgst}%)</Typography>
            <Typography variant="body2" textTransform="uppercase">
              {formatCurrencyWithSymbol((data?.sgst)*(calculateFinalAmount(data))/100, data?.currency?.abbreviation)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">IGST ({data?.igst}%)</Typography>
            <Typography variant="body2" textTransform="uppercase">
              {formatCurrencyWithSymbol((data?.igst)*(calculateFinalAmount(data))/100, data?.currency?.abbreviation)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">VAT ({data?.vat}%)</Typography>
            <Typography variant="body2" textTransform="uppercase">
              {formatCurrencyWithSymbol((data?.vat)*(calculateFinalAmount(data))/100, data?.currency?.abbreviation)}
            </Typography>
          </Stack>
         
          <Divider sx={{ my: 1, borderColor:'red' }} />
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">Total Value</Typography>
            <Typography variant="subtitle1" textTransform="uppercase">
              {formatCurrencyWithSymbol((calculateFinalAmount(data)*(((data?.cgst+data?.sgst+data?.igst+data?.vat)/100)+1)), data?.currency?.abbreviation)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
