import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { getSignedURltoviewFile } from 'src/services/file';

const PdfPreviewPopup = ({ pdfUrl, label, sx }) => {
  const [fileUrl, setFileUrl] = useState('');
  const [errorPreview, setErrorPreview] = useState(null);

  const [open, setOpenPdfPopup] = useState(false);

  const handleOpenPdfPopup = () => {
    setOpenPdfPopup(true);
  };

  const handleClosePdfPopup = () => {
    setOpenPdfPopup(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = await getSignedURltoviewFile(pdfUrl);
        setFileUrl(url);
        setErrorPreview(null);
      } catch (error) {
        console.error('Error fetching PDF URL:', error);
        setErrorPreview('Error loading PDF content');
      }
    };

    fetchData();
  }, [pdfUrl, open]);
  return (
    <>
      <Button
        disabled={!pdfUrl}
        variant="outlined"
        style={{
          textTransform: 'uppercase',
          // backgroundColor: 'black',
          // color: 'white',
          width:'fit-content',
          minHeight: '35px',
          backgroundColor: '#D9D9D9',
          px: 5,
          color:'black',
          borderRadius: 16,
          width: '200px',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
        sx={sx}
        onClick={() => handleOpenPdfPopup()}
      >
        {label}
      </Button>
      <Dialog open={open} onClose={handleClosePdfPopup} maxWidth="md" fullWidth>
        <DialogTitle>FILE VIEWER</DialogTitle>
        {fileUrl && !errorPreview ? (
          <>
            <DialogContent>
              <iframe
                title="PDF Viewer"
                src={fileUrl}
                width="100%"
                height="700px"
                frameBorder="0"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePdfPopup}>CLOSE</Button>
            </DialogActions>
          </>
        ) : (
          <DialogContent>{errorPreview}</DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default PdfPreviewPopup;
