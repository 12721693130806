import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Container, Typography, alpha, Card } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import { CurrencyPicker } from 'src/components/currency-picker/currency-picker';
import { useParams, useRouter } from 'src/routes/hooks';
// import VendorQuoteOrdersTable from '../vendorQuoteOrdersTable';
import { getAllVendorApprovedRequests } from 'src/services/vendorDashboard';
import EshopQuoteOrdersCommonTable from '../eshopOrderDeliveryTable';
import VendorQuotesNav from 'src/components/Navs/VendorQuotesNav';
import { useOutletContext } from 'react-router';
export default function VendorQuoteOrdersView() {
  const settings = useSettingsContext();
  const router = useRouter();
  const { id, partId, vid } = useParams();
  const [data] = useOutletContext();
  const [requestData, setRequestData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [currency, setCurrency] = useState({});

  useEffect(() => {
    getAllVendorApprovedRequests(id, partId, vid).then((vdata) => {
      setRequestData(vdata);
      setCurrency(vdata?.[0]?.quote?.currency);
    });
  }, [refresh]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Box display="flex">
        <Box>
          <CustomBreadcrumbs
            heading="ESHOP Quote Order"
            links={[
              { name: 'Home', href: '/home' },
              {
                name: 'Vendor',
                href: `/quote/${id}/vendor`,
              },
              {
                name: 'Vendor Selection',
                href: `/quote/${id}/vendor/vendor-selection`,
              },
              {
                name: 'ESHOP',
                href: `/quote/${id}/eshop`,
              },
              {
                name: 'ESHOP Order',
                href: '/eshop',
              },
            ]}
            sx={{ mb: { xs: 3, md: 5 } }}
          />
          <Box display="flex" justifyContent="space-between">
          <Box display={'flex'} >
              <Button
                variant="contained"
                href={`/quote/${id}/eshop`}
                sx={{
                  borderRadius: 0,
                  color:"black",
                  ml: 1,
                  width: 240,
                  fontSize: 14,
                  backgroundColor: 'grey',
                  mt:1
                }}
              >
                ESHOP
              </Button>
              <Button
                href={`/quote/${id}/eshop/eshop-orders`}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray',borderTop: '5px solid red'}}
              >
                ORDERS
              </Button>
              <Button
                href={`/quote/${id}/eshop/eshop-archive`}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray', mt: 1 }}
              >
                ARCHIVE
              </Button>
          </Box>
          </Box>
        </Box>
             <Box borderLeft={2} marginLeft={2} paddingLeft={2} borderColor={'white'} color={'white'}>
             <Card sx={{p:2}}>
          <Typography
            textTransform="uppercase"
            variant="caption"
          >{`Project ID: ${data?.projectId}`}</Typography>
          <br />
          <Typography textTransform="uppercase" variant="caption">{`RFQ: ${data?.RFQ}`}</Typography>
          <br />
          <Typography textTransform="uppercase" variant="caption">{`RFQ NAME: ${data?.RFQName}`}</Typography>
          <br />
          <Typography textTransform="uppercase" variant="caption">
            {'Recently edited by - {User Name}'}
          </Typography>
          </Card>
        </Box>
      </Box>
      {/* <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: 1,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 2,
          mt: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Box>
          <Chip
            style={{ borderRadius: 1, marginRight: 10, textTransform: 'uppercase' }}
            label={`Project ID: ${data.projectId}`}
          />
          <Chip
            style={{ borderRadius: 1, textTransform: 'uppercase' }}
            label={`RFQ: ${data.RFQ}`}
            variant="outlined"
          />
        </Box>
        <Typography textTransform="uppercase" variant="caption">
          {'Recently edited by - {User Name}'}
        </Typography>
      </Box> */}

      <EshopQuoteOrdersCommonTable
        requestData={requestData}
        currency={currency}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </Container>
  );
}
